import { RouteConfig } from "vue-router";

export default [
  {
    path: "/admin/annuities",
    name: "admin_annuities",
    component: () =>
      import(
        /* webpackChunkName: "admin_annuities_dashboard" */ "../views/admin/AdminAnnuityDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Annuities | Admin",
    },
  },
  {
    path: "/admin/annuity/:id",
    name: "admin_annuity",
    component: () =>
      import(
        /* webpackChunkName: "admin_annuity_view" */ "../views/admin/AdminAnnuityView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Annuity | Admin",
    },
  },
  {
    path: "/admin/advisors",
    name: "AdminAgentsView",
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Advisors | Admin",
    },
    component: () =>
      import(
        /* webpackChunkName: "admin_advisors" */ "../views/admin/AgentsView.vue"
      ),
  },
] as RouteConfig[];
