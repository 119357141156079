// Firebase Stuff

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCV5numCGoJDF8QbBAr1DOZJ7wFez5W4mQ",
  // authDomain: "io-annuityscore.firebaseapp.com",
  authDomain: "auth.annuityscore.io",
  projectId: "io-annuityscore",
  storageBucket: "io-annuityscore.appspot.com",
  messagingSenderId: "915233750075",
  appId: "1:915233750075:web:2597563b857276cf8fb01e",
  measurementId: "G-J8941EXNPL",
};

const fb_app = initializeApp(firebaseConfig);
const analytics = getAnalytics(fb_app);
const auth = getAuth(fb_app);
// connectAuthEmulator(auth, "http://192.168.1.6:9099");
const db = getFirestore(fb_app);
// connectFirestoreEmulator(db, "http://192.168.1.6", 8099);
const storage = getStorage(fb_app);

export { fb_app, analytics, auth, db, storage };
