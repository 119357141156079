
import Vue from "vue";
import { auth } from "@/firebaseConfig";
import { signOut } from "firebase/auth";
export default Vue.extend({
  name: "App",
  created() {},
  mounted() {
    this.resizeOnUserStateChange();
    this.scrollToTop();

    if (document.location.pathname != "/") {
      this.bg = this.bg_theme;
    } else {
      window.onscroll = () => {
        this.changeColor();
      };
    }
  },
  computed: {
    //create a function that returns the login state
    c_state() {
      if (this.$store.state.user.is_login) {
        if (this.$store.state.user.acl === 10) {
          return "logged_in_admin";
        } else if (this.$store.state.user.acl >= 5) {
          return "logged_in_marketer";
        } else if (
          this.$store.state.user.acl >= 1 ||
          this.$store.state.user.recurlyStatus === "active"
        ) {
          return "logged_in_paid";
        } else {
          return "logged_in_not_paid";
        }
      } else {
        return "not_logged_in";
      }
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    changeColor() {
      if (document.location.pathname != "/") {
        this.bg = this.bg_theme;
      } else {
        if (
          // document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 120
          // $(window).scrollTop() !== 0
        ) {
          this.bg = this.bg_theme; //bg_theme
        } else {
          this.bg = "transparent";
        }
      }
    },
    async resizeOnUserStateChange() {
      await this.$store.state.user_loading;
      window.dispatchEvent(new Event("resize"));
    },
    signout() {
      signOut(auth)
        .then(() => {
          window.location.href = "/";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {},
  data() {
    return {
      bg_theme: this.$vuetify.theme.currentTheme.background as string,
      bg: "transparent",
      tab: null,
      drawer: false,
      group: null,
      c_state_nav: {
        logged_in_admin: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Dashboard",
            icon: "mdi-calculator",
            to: "/dashboard",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
          {
            text: "Advisors",
            icon: "mdi-account-group",
            to: "/admin/advisors",
            menu_item: true,
          },
          {
            text: "Annuities",
            icon: "mdi-text-account",
            to: "/admin/annuities",
            menu_item: true,
          },
        ] as any[],
        logged_in_marketer: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Dashboard",
            icon: "mdi-calculator",
            to: "/dashboard",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
          {
            text: "Advisors",
            icon: "mdi-account-group",
            to: "/marketer/advisors",
            menu_item: true,
          },
          {
            text: "Annuities",
            icon: "mdi-text-account",
            to: "/marketer/annuities",
            menu_item: true,
          },
        ] as any[],
        logged_in_paid: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Dashboard",
            icon: "mdi-calculator",
            to: "/dashboard",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
        ] as any[],
        logged_in_not_paid: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Pricing",
            icon: "mdi-calculator",
            to: "/pricing",
          },
          {
            text: "Account",
            icon: "mdi-account",
            to: "/account",
          },
          {
            text: "Sign Out",
            icon: "mdi-logout",
            to: "/",
            custom_action: (this as any).signout,
          },
        ] as any[],
        not_logged_in: [
          {
            text: "Home",
            icon: "mdi-home",
            to: "/",
          },
          {
            text: "Pricing",
            icon: "mdi-storefront",
            to: "/pricing",
          },
          {
            text: "Sign In",
            icon: "mdi-login",
            to: "/login",
          },
          {
            text: "Sign Up",
            icon: "mdi-account-plus-outline",
            to: "/register",
          },
        ] as any[],
      },
    };
  },
});
