import { RouteConfig } from "vue-router";

export default [
  {
    path: "/account",
    name: "account",
    meta: {
      requiresAuth: true,
      title: "Account",
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
  },
  {
    path: "/new-annuity/:uid?",
    name: "NewAnnuityView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "New Annuity",
    },
    component: () =>
      import(
        /* webpackChunkName: "new_annuity" */ "../views/NewAnnuityView.vue"
      ),
  },

  {
    path: "/annuity/:id",
    name: "AnnuityView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Annuity",
    },
    component: () =>
      import(/* webpackChunkName: "annuity" */ "../views/AnnuityView.vue"),
  },
  {
    path: "/new-statement/:id",
    name: "NewStatementView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "New Statement",
    },
    component: () =>
      import(
        /* webpackChunkName: "new_statement" */ "../views/NewStatementView.vue"
      ),
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Dashboard",
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
] as RouteConfig[];
