var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{style:({ background: _vm.bg_theme })},[_c('v-navigation-drawer',{attrs:{"fixed":"","temporary":"","dark":"","right":"","clipped":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('v-list-item',[_c('v-img',{staticClass:"mr-2 mt-5 mb-5",attrs:{"alt":"AnnuityScore","contain":"","src":require("@/assets/annuityscore.png"),"transition":"scale-transition","width":"120"},on:{"click":_vm.scrollToTop}})],1),_vm._l((_vm.c_state_nav[_vm.c_state].filter(
            (item) => !item.menu_item
          )),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.custom_action ? '' : tab.to},on:{"click":function($event){$event.stopPropagation();tab.custom_action ? tab.custom_action() : null}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(tab.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tab.text))])],1)],1)}),(_vm.c_state === 'logged_in_admin')?_c('div',[_c('v-divider',{staticClass:"mt-2 mb-2"}),_c('h3',[_vm._v("Admin")]),_vm._l((_vm.c_state_nav.logged_in_admin.filter(
              (item) => item.menu_item
            )),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.to}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(tab.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tab.text))])],1)],1)})],2):_vm._e(),(
            _vm.c_state === 'logged_in_marketer' || _vm.c_state === 'logged_in_admin'
          )?_c('div',[_c('v-divider',{staticClass:"mt-2 mb-2"}),_c('h3',[_vm._v("Marketer")]),_vm._l((_vm.c_state_nav.logged_in_marketer.filter(
              (item) => item.menu_item
            )),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.to}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(tab.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tab.text))])],1)],1)})],2):_vm._e()],2)],1)],1),_c('v-app-bar',{staticClass:"bartransition py-0",style:({ 'background-color': _vm.bg }),attrs:{"app":"","dark":"","elevation":"0"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"mr-2",attrs:{"alt":"AnnuityScore","contain":"","src":"/assets/annuityscore.png","transition":"scale-transition","width":"220","height":"100%"},on:{"click":_vm.scrollToTop}})],1)],1),_c('v-spacer'),(_vm.$vuetify.breakpoint.xs)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_c('v-tabs',{attrs:{"right":"","optional":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.c_state_nav[_vm.c_state].filter(
          (item) => !item.menu_item
        )),function(tab,index){return _c('v-tab',{key:index,staticClass:"nav-item",attrs:{"to":tab.custom_action ? '' : tab.to},on:{"click":function($event){$event.stopPropagation();tab.custom_action ? tab.custom_action() : null}}},[_vm._v(" "+_vm._s(tab.text)+" ")])}),(_vm.c_state === 'logged_in_admin')?_c('v-menu',{attrs:{"bottom":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"nav-item align-self-center mr-4",staticStyle:{"font-weight":"300"},attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Admin "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1090931437)},[_c('v-list',_vm._l((_vm.c_state_nav.logged_in_admin.filter(
              (item) => item.menu_item
            )),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.to}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(tab.text)+" ")])],1)],1)}),1)],1):_vm._e(),(
          _vm.c_state === 'logged_in_marketer' || _vm.c_state === 'logged_in_admin'
        )?_c('v-menu',{attrs:{"bottom":"","offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"nav-item align-self-center mr-4",staticStyle:{"font-weight":"300"},attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Marketer "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,1243428401)},[_c('v-list',_vm._l((_vm.c_state_nav.logged_in_marketer.filter(
              (item) => item.menu_item
            )),function(tab,index){return _c('v-list-item',{key:index,attrs:{"link":"","to":tab.to}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(tab.text)+" ")])],1)],1)}),1)],1):_vm._e()],2)],1),_c('v-main',[_c('router-view')],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.$store.state.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.$store.state.snackbar.show),callback:function ($$v) {_vm.$set(_vm.$store.state.snackbar, "show", $$v)},expression:"$store.state.snackbar.show"}},[_vm._v(" "+_vm._s(_vm.$store.state.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }