import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/firebaseConfig";
import store from "@/store";

import loginRoutes from "./loginRoutes";
import marketerRoutes from "./marketerRoutes";
import userRoutes from "./userRoutes";
import adminRoutes from "./adminRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "AnnuityScore",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "About",
    },
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () =>
      import(/* webpackChunkName: "pricing" */ "../views/PricingView.vue"),
    meta: {
      title: "Pricing",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...routes,
    ...userRoutes,
    ...adminRoutes,
    ...loginRoutes,
    ...marketerRoutes,
  ],
  scrollBehavior() {
    document.getElementById("app")!.scrollIntoView({ behavior: "auto" });
  },
});

const user = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userFirebase) => {
        unsubscribe();
        resolve(userFirebase);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const requiresSub = to.matched.some((record) => record.meta.requiresSub);

  // They are accessing an unsecured resource
  if (!requiresAuth) {
    return next();
  }

  const userFirebase = (await user()) as any;

  // They aren't logged in at all, and need to be
  if (requiresAuth && !userFirebase) {
    store.state.requested_route = to.path;
    return next("login");
  }

  // At this point, they are logged in
  // and a secure resource

  // They are admin, so we dont need to wait on the route
  const token = await userFirebase.getIdTokenResult();
  if (token.claims.acl === 10) {
    return next();
  }

  // They aren't admin, but want to go to admin route
  if (requiresAdmin) {
    return next("dashboard");
  }

  if (!requiresSub) {
    // They are attempting to access a page
    // that does not require a subscription
    return next();
  }

  if (token.claims.acl >= 1) {
    return next();
  }

  // They are a reg user, attempting to access a page
  // that requires a subscription to access
  // if they have an account with us
  await store.state.user_loading;
  if (store.state.user.recurlyStatus !== "active") {
    //  They are reg users, who dont have a subscription
    return next("checkout");
  }
  // "They are regular users, who have a subscription, and are accessing a restricted page
  //  that requires a subscription"
  return next();
});

router.afterEach((to) => {
  let main_title = "AnnuityScore";
  let title = main_title;
  let to_title: string | undefined = to.meta?.title;
  if (to_title && to_title !== main_title) {
    title = `${to_title} | ${main_title}`;
  }
  document.title = title;
});

export default router;
